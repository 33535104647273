import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

import { clientLog } from '@core/Utils/Log/ClientLog';
import { isWindowDefined } from '@core/Constants/Window';
import { extractSchemaName } from './ExtractSchemaName';
import { getSchemaByRef } from './GetSchemaByRef';
import { validateSchema } from './ValidateSchema';
import { TrackingEvent } from '../Entities/Schema.entity';

export const trackCustomEvent = ({
  event,
  context,
}: {
  event: TrackingEvent;
  context: TrackingEvent[];
}) => {
  context?.map(event => validateSchema(getSchemaByRef(event.schema), event));
  trackSelfDescribingEvent({ event, context });

  const eventObject = { event: extractSchemaName(event.schema), data: event.data, context };

  // @ts-expect-error - window typing
  if (isWindowDefined) (window.dataLayer ||= []).push(eventObject);
  // @ts-expect-error - window typing
  if (isWindowDefined) (window.eventHistory ||= []).push(eventObject);

  clientLog({ data: { event, context }, label: `Event: ${event.schema}`, color: '#e6faf6' });
};
